<script setup lang="ts">
import { NuxtLink } from '#components'
import type { FetchError } from 'ofetch'
import type { SlideImageFormat, SlideSize } from '~/types/api/slider'
import type { Carousel } from '~/types/ui'

interface Slide {
  name: string
  url: string
  voucher: string
  images: {
    normal: string
    retina: string
    type: string
    media: string
  }[]
  originImage: {
    normal: string
    retina: string
    type: string
    media: string
  }
}

const $img = useImage()
function fetchImage(url: string, args: object) {
  return $img(url, { quality: 55, ...args })
}

const SlideImageFormatMap: Record<SlideImageFormat, string> = {
  jpg: 'image/jpeg',
  jpeg: 'image/jpeg',
  png: 'image/png',
  avif: 'image/avif',
  webp: 'image/webp',
}

const SlideSizeMediaMap: Record<SlideSize, string> = {
  wide: 'screen and (min-width: 1280px)',
  desktop: 'screen and (min-width: 1024px) and (max-width: 1279px)',
  tablet: 'screen and (min-width: 768px) and (max-width: 1023px)',
  mobile: 'screen and (min-width: 640px) and (max-width: 767px)',
  origin: 'screen and (max-width: 639px)',
}

// NOTE: The height is doubled for retina images
const SlideHeightMap: Record<SlideSize, number> = {
  wide: 800,
  desktop: 600,
  tablet: 500,
  mobile: 500,
  origin: 500,
}

const slides = await useNuxtApp()
  .$api.slider.getSlides()
  .then((slides) => {
    return slides.map((slide) => {
      const images = slide.images.flatMap((image) => {
        const [size, type] = image.key.split('.')
        const height = SlideHeightMap[size as SlideSize]

        return ['avif', 'webp', type].map((format) => {
          const type = SlideImageFormatMap[format as SlideImageFormat]
          const media = SlideSizeMediaMap[size as SlideSize]

          return {
            normal: fetchImage(image.normal, { format, height }),
            retina: fetchImage(image.retina, { format, height }),
            type,
            media,
          }
        })
      })

      const originImage = images[images.length - 1]

      return {
        ...slide,
        images,
        originImage,
      }
    })
  })

function applyVoucher(voucher: string) {
  useNuxtApp()
    .$api.order.getVoucher(voucher, {
      amount: 1e7,
    })
    .then(async (response) => {
      useOrder().setVoucher({
        code: response.code,
        discount: response.value,
      })

      const modal = await import('~/components/ui/UIVoucherAppliedModal.vue')
      useModal().open(modal.default, {
        voucher: response,
      })
    })
    .catch((e: FetchError) => {
      useSentry().captureException(e)
    })
}

const carousel = ref<Carousel | null>(null)
const { isActive, pause, resume } = useIntervalFn(() => {
  if (!carousel.value) return

  if (carousel.value.page === carousel.value.pages) {
    return carousel.value.select(0)
  }

  return carousel.value.next()
}, 5000)

// TODO: Preload first carousel image using `useHead` and srcset
const firstSlide = slides[0]
const preloadImages = firstSlide.images.filter(
  (image) => image.type === 'image/avif',
)
useHead({
  link: preloadImages.map((image) => ({
    rel: 'preload',
    as: 'image',
    href: image.retina,
    type: image.type,
    media: image.media,
  })),
})
</script>

<template>
  <div class="relative">
    <UCarousel
      ref="carousel"
      arrows
      :items="slides"
      :ui="{
        container: 'h-[250px] lg:h-[300px] xl:h-[400px]',
        item: 'basis-full justify-center',
      }"
    >
      <template
        #default="{ item: slide, index }: { item: Slide; index: number }"
      >
        <component
          :is="slide.url ? NuxtLink : 'div'"
          :to="!!slide.url ? slide.url : undefined"
          @click="slide.voucher && applyVoucher(slide.voucher)"
        >
          <picture>
            <source
              v-for="(image, i) in slide.images"
              :key="`${slide.name}-${i}`"
              :srcset="`${image.retina} 2x, ${image.normal} 1x`"
              :type="image.type"
              :media="image.media"
            />
            <img
              v-if="slide.originImage"
              :src="slide.originImage.retina"
              :alt="slide.name"
              :loading="index === 0 ? 'eager' : 'lazy'"
              class="mx-auto h-full object-contain"
            />
          </picture>
        </component>
      </template>
    </UCarousel>
    <div class="absolute bottom-4 right-4">
      <UButton
        color="black"
        variant="outline"
        :icon="isActive ? 'i-fas-pause' : 'i-fas-play'"
        @click="isActive ? pause() : resume()"
      />
    </div>
  </div>
</template>
